import React, { useContext, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useStripeProducts from "../products/useStripeProducts";
import useStripeDiscountedProducts from "../products/useStripeDiscountedProducts";
import { formatCurrencyString, useShoppingCart } from "use-shopping-cart";
import { Backdrop, Card, CircularProgress } from "@mui/material";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { Button } from "gatsby-theme-material-ui";
import DoneIcon from "@mui/icons-material/Done";

const SoftwareUpsellSection = ({ allStripeProducts, ...props }) => {
  const [open, setOpen] = useState(false);

  const { addItem } = useShoppingCart();
  const products = useStripeProducts();
  const productsDisc = useStripeDiscountedProducts();

  const premiumTierDisc = productsDisc.find(
    (product) => product.displayName === "Premium Tier"
  );
  const premiumTier = products.find(
    (product) => product.displayName === "Premium Tier"
  );

  const standardTierDisc = productsDisc.find(
    (product) => product.displayName === "Standard Tier"
  );

  const standardTier = products.find(
    (product) => product.displayName === "Standard Tier"
  );

  const miniTierDisc = productsDisc.find(
    (product) => product.displayName === "Mini Tier"
  );

  const miniTier = products.find(
    (product) => product.displayName === "Mini Tier"
  );

  const addMiniTier = () => {
    setOpen(true);
    addItem(miniTierDisc);
    setTimeout(() => {
      setOpen(false);
    }, 700);
  };

  const addStandardTier = () => {
    setOpen(true);
    addItem(standardTierDisc);
    setTimeout(() => {
      setOpen(false);
    }, 700);
  };

  const addPremiumTier = () => {
    setOpen(true);
    addItem(premiumTierDisc);
    setTimeout(() => {
      setOpen(false);
    }, 700);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 1, marginTop: 0 }}>
      <Container maxWidth={"lg"}>
        <h1 style={{ marginTop: 15, textAlign: "center" }}>
          Choose your software Tier.
        </h1>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid
            xs={12}
            sm={5.8}
            md={3.7}
            className="margin_top_1"
            style={{ display: "flex" }}
          >
            <Card
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "1em",
                  marginBottom: "1em",
                  textAlign: "center",
                }}
              >
                {miniTier.displayName}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "bold",
                  }}
                >
                  {formatCurrencyString({
                    value: miniTier.price,
                    currency: miniTier.currency,
                  })}
                  /Yearly
                </div>
                <div style={{ color: "#2EC4B6" }}>
                  {formatCurrencyString({
                    value: miniTierDisc.price,
                    currency: miniTierDisc.currency,
                  })}
                  /Yearly
                </div>
              </div>
              <p>
                For <b>image-centric specialists</b> - 10GB cloud storage
                exclusively for images.
              </p>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    10 GB of cloud storage for <b>Images.</b>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    Multi-device access.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    Unlimited link share of images.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    In-app video edition.
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "1em", marginTop: "auto" }}>
                <a
                  href="/connect"
                  target="_blank"
                  style={{
                    color: "#838383",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Learn more
                </a>
              </div>
              <div style={{ textAlign: "center" }}>
                {" "}
                <Button
                  onClick={addMiniTier}
                  color="primary"
                  variant="contained"
                  endIcon={<AddShoppingCartOutlinedIcon />}
                  sx={{
                    padding: "8px 30px",
                    marginTop: "1em",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Add to Cart
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Card>
          </Grid>
          <Grid
            xs={12}
            sm={5.8}
            md={3.7}
            className="margin_top_1"
            style={{ display: "flex" }}
          >
            <Card
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "visible",
                borderRadius: "10px",
              }}
            >
              <div
                className="best_seller"
                style={{
                  left: "50%",
                  transform: "translate(-50%, -20%)",
                  width: "70%",
                }}
              >
                🔥 BEST SELLER
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "1em",
                  marginBottom: "1em",
                  textAlign: "center",
                }}
              >
                {standardTier.displayName}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "bold",
                  }}
                >
                  {formatCurrencyString({
                    value: standardTier.price,
                    currency: standardTier.currency,
                  })}
                  /Yearly
                </div>
                <div style={{ color: "#2EC4B6" }}>
                  {formatCurrencyString({
                    value: standardTierDisc.price,
                    currency: standardTierDisc.currency,
                  })}
                  /Yearly
                </div>
              </div>
              <p>
                For <b>professionals emphasizing recordings</b>, 500GB cloud
                storage is optimized for both pictures and videos.
              </p>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    500 GB of cloud storage for <b>Images and Videos.</b>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    Multi-device access.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    Unlimited link share of images.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    In-app video edition.
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em", fontWeight: "600" }}>
                    + 1 Year of Warranty{" "}
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "1em", marginTop: "auto" }}>
                <a
                  href="/connect"
                  target="_blank"
                  style={{
                    color: "#838383",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Learn more
                </a>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={addStandardTier}
                  color="primary"
                  variant="contained"
                  endIcon={<AddShoppingCartOutlinedIcon />}
                  sx={{
                    padding: "8px 30px",
                    marginTop: "1em",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Add to Cart
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Card>
          </Grid>
          <Grid
            xs={12}
            sm={5.8}
            md={3.7}
            className="margin_top_1"
            style={{ display: "flex" }}
          >
            <Card
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "1em",
                  marginBottom: "1em",
                  textAlign: "center",
                }}
              >
                {premiumTier.displayName}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "bold",
                  }}
                >
                  {formatCurrencyString({
                    value: premiumTier.price,
                    currency: premiumTier.currency,
                  })}
                  /Yearly
                </div>
                <div style={{ color: "#2EC4B6" }}>
                  {formatCurrencyString({
                    value: premiumTierDisc.price,
                    currency: premiumTierDisc.currency,
                  })}
                  /Yearly
                </div>
              </div>
              <p>
                For <b>professionals emphasizing recordings</b>, 2 TB cloud
                storage is optimized for both pictures and videos.
              </p>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    2 TB of cloud storage for <b>Images and Videos.</b>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    Multi-device access.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    Unlimited link share of images.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em" }}>
                    In-app video edition.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <DoneIcon sx={{ color: "#8F4FFF" }} />{" "}
                  <div style={{ marginLeft: "0.5em", fontWeight: "600" }}>
                    Unlimited warranty while you are a MicroREC Connect user.
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "1em", marginTop: "auto" }}>
                <a
                  href="/connect"
                  target="_blank"
                  style={{
                    color: "#838383",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Learn more
                </a>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={addPremiumTier}
                  color="primary"
                  variant="contained"
                  endIcon={<AddShoppingCartOutlinedIcon />}
                  sx={{
                    padding: "8px 30px",
                    marginTop: "1em",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Add to Cart
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SoftwareUpsellSection;
